<template>
  <div class="home px-4 py-2">
    <div class="text-h6 ma-0 pa-0" v-if="hasLive">Live now</div>
    <v-container class="mx-0" v-if="hasLive">
      <v-row>
        <v-col v-for="(video, twitchName) in twitch" :key="video.key" @click="goWatch()"
          cols="12" xl="2" lg="3" md="4" sm="6">
          <Thumbnail
            :id="video.id"
            :twitch="twitchName"
            :image="video.image"
            :title="video.title"
            :game="video.game"
            :viewers="video.viewers"
            live="1" />
        </v-col>
        <v-col v-for="video in live" :key="video.key" @click="goWatch()"
          cols="12" xl="2" lg="3" md="4" sm="6">
          <Thumbnail
            :id="video.id"
            :channelId="video.channelId"
            :title="video.title"
            :start="video.start || video.scheduled"
            :viewers="video.viewers"
            live="1" />
        </v-col>
      </v-row>
    </v-container>

    <div class="text-h6 ma-0 pa-0" v-show="upcoming.length">Upcoming Streams</div>
    <v-container class="mx-0" v-show="upcoming.length">
      <v-row>
        <v-col v-for="video in upcoming" :key="video.key" cols="12" xl="2" lg="3" md="4" sm="6">
          <a :href="`https://www.youtube.com/watch?v=${video.id}`" target="_blank" class="video-link">
            <Thumbnail
              :id="video.id"
              :channelId="video.channelId"
              :title="video.title"
              :schedule="video.scheduled" />
          </a>
        </v-col>
      </v-row>
    </v-container>

    <div class="text-h6 ma-0 pa-0">Check their socials for schedule</div>
    <v-container class="mx-0">
      <v-row>
        <v-col v-for="({ key, banner, image, twitter }) in channels" :key="`tweet-${key}`"
          v-show="twitter"
          cols="12" xl="3" lg="3" md="4" sm="6">
          <a :href="`https://twitter.com/${twitter}`" target="_blank">
            <v-img :src="banner || image" style="width:100%; height:70%" />
          </a>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Thumbnail from '@/components/Thumbnail.vue';

export default {
  name: 'Home',
  data: () => ({
    //
  }),
  computed: {
    hasLive() {
      return Object.keys(this.twitch).length || this.live.length;
    },
    channels() {
      return this.$store.state.channels;
    },
    twitch() {
      return this.$store.state.twitch;
    },
    live() {
      return this.$store.state.live;
    },
    upcoming() {
      return this.$store.state.upcoming;
    },
    tweets() {
      return this.$store.state.tweets;
    },
  },
  methods: {
    goWatch() {
      this.$router.push({ name: 'Watch' }).catch(() => null);
    },
  },
  mounted() {
    if (!this.$isMobile) this.$store.dispatch('openDrawer');
  },
  components: {
    Thumbnail,
  },
};
</script>
